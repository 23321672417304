<template>
   <div class="row">
      <div class="col-6">
         <h1>{{$t('home.welcome')}}, {{name}}</h1>
      </div>
      <div class="col-6">
         <h2 style="text-align: right;">{{ $t('home.greetings') + formatDate(new Date(), 'verylongDate') }}</h2>
      </div>
   </div>      
 
   <div id="dashboard-statistics" class="row justify-content-between">
      <div class="col-3">
         <div class="grid-item">
            <h3>{{$t('home.daystates_imported')}}</h3>
            <span class="itemnmbr">{{data.daystates_imported}}</span>
            <div class="item-yesterday"><span><i :class="data.daystates_imported_icon" :data-count="data.daystates_imported_yesterday"></i></span> {{$t('home.vs_yesterday')}}</div>
            <img src="../assets/grafiek.jpg" alt="grafiek">
         </div>
      </div>
 
      <div class="col-3">
         <div class="grid-item">
            <h3>{{$t('home.daystates_exported')}}</h3>
            <span class="itemnmbr">{{data.daystates_exported}}</span>
            <div class="item-yesterday"><span><i :class="data.daystates_exported_icon" :data-count="data.daystates_exported_yesterday"></i></span> {{$t('home.vs_yesterday')}}</div>
            <img src="../assets/grafiek.jpg" alt="grafiek">
         </div>      
      </div>
 
      <div class="col-3">
         <div class="grid-item">
            <h3>{{$t('home.locations_amount')}}</h3>
            <span class="itemnmbr">{{data.total_locations}}</span>
            <div class="item-yesterday"><span><i :class="data.locations_vs_last_month_icon" :data-count="data.locations_vs_last_month"></i></span> {{$t('home.vs_last_month')}}</div>
            <img src="../assets/staafdiagram.jpg" alt="grafiek">
         </div>       
      </div>
    </div>
 
   <div id="status-location" class="row">
      <div class="col-6">
         <h2>{{$t('home.locations_new')}}</h2>
         <div v-for="location in data.newest_locations" :key="location.id">
            <label>{{formatDate(location.created_at, 'fromNowOrShort')}}</label> 
            <div class="status-bubble bg-success"></div> {{location.name + (location.dealer ? ` (${location.dealer.name})` : '')}}
         </div>
         <!--<div><label>Vandaag</label> <div class="status-bubble bg-success"></div> Black Smoke Rotterdam B.V. (UPTA Horeca Automatisering)</div>
         <div><label>Gisteren</label> <div class="status-bubble bg-warning"></div> Van der Valk Assen (Adnamics B.V.)</div>
         <div><label>28 sept</label> <div class="status-bubble bg-warning"></div> Van der Valk Maastricht (Adnamics B.V.)</div>
         <div><label>25 sept</label> <div class="status-bubble bg-success"></div> Eterij De Stallen (Adnamics B.V.)</div>
         <div><label>2 sept</label> <div class="status-bubble bg-success"></div> Cafe 't Fust B.V. (AlfaPOS)</div>-->
      </div>
 
      <div class="col-6">
         <h2>{{$t('home.locations_offline')}}</h2>
         <div v-for="location in data.offline_locations" :key="location.id">
            <label>{{formatDate(location.last_connection, 'daysSince')}}</label> 
            <div class="status-bubble bg-danger"></div> {{location.name + (location.dealer ? ` (${location.dealer.name})` : '')}}
         </div>
         <!--<div><label>263 dagen</label> <div class="status-bubble bg-danger"></div> Citta Romana (UPTA Horeca Automatisering)</div>
         <div><label>151 dagen</label> <div class="status-bubble bg-danger"></div> Burger Bar - Damstraat (Adnamics B.V.)</div>
         <div><label>101 dagen</label> <div class="status-bubble bg-danger"></div> Eetcafe De Schuimspaan B.V. (Adnamics B.V.)</div>
         <div><label>22 dagen</label> <div class="status-bubble bg-danger"></div> BBrood - Haarlemmerdijk (bij Brandt) (Adnamics B.V.)</div>
         <div><label>1 dag</label> <div class="status-bubble bg-danger"></div> testkassa Netvibes kantoor (AlfaPOS)</div>-->
      </div>
   </div>
 
   <div id="errormessages" class="row">
      <div class="col-9">
         <h2>{{$t('home.latest_error_messages')}}</h2>
         <div><label>Vandaag, 09:44</label> Error getting GL accounts (Black Smoke Rotterdam B.V.)</div>
         <div><label>Vandaag, 07:32</label> Snelstart tijdelijk offline (Loetje aan t IJ)</div>
         <div><label>Gisteren, 12:59</label> Error getting GL accounts (Loetje Centraal)</div>
         <div><label>Gisteren, 09:44</label> Exact Online tijdelijk offline (Carter)</div>
         <div><label>27 sept, 20:00</label> Fout bij dagstaat exporteren (Eterij De Stallen)</div>
      </div>
   </div>
</template>

<script>
    import store from '@/store/user';
    import DataService from '@/services/DataService';
    export default {
        name: 'Home',
        data() {
            return {
                name: '',
                data: {
                  daystates_imported: 0,
                  daystates_imported_icon: '',
                  daystates_imported_yesterday: 0,
                  daystates_exported: 0,
                  daystates_exported_icon: '',
                  daystates_exported_yesterday: 0,
                  total_locations: 0,
                  locations_vs_last_month: 0,
                  locations_vs_last_month_icon: '',
                  newest_locations: [],
                  offline_locations: [],
                  lastest_errors: []
                }
            }
        },
        methods: {
            //
        },
        mounted(){
            this.name = store.getters.getName;
            DataService.getDashboardData().then(response => {
                this.data = response.data;
            }).catch(error => {
                console.log(error);
            });
        }
    }

</script>

<style scoped>
 
   h1, h2, h3 {
      color: var(--uni-dark);
   }

   h2 {
      margin-bottom: 15px;
   }

   img{
      width: 100%;
      margin-top: 1rem
   }

   .item-yesterday {
      font-weight: 600;
   }

   #dashboard-statistics .col-3 .grid-item {
      background-color: rgba(27,92,150,0.1);
      text-align: center;
      border-radius: 4px;
      padding: 2em;
   }

   #dashboard-statistics .col-3 .grid-item .itemnmbr {
      font-size: 90px;
      font-weight: bold;
   }

   .status-bubble {
      width: 13px;
      height: 13px;
      border-radius: 15px;
      display: inline-block;
      margin-right: 5px;
   }

   #status-location label, #errormessages label {
      min-width: 90px;
      display: inline-block;
   }

   #dashboard-statistics, #status-location {
      margin-bottom: 4em;
   }

   #dashboard-statistics {
      margin-top: 4em;
   }

   i.fa-solid::after {
      content: attr(data-count);
      margin-left: 5px;
      font-weight: 600;
      font-family: 'Inter', sans-serif;
      font-size: 15px;
   }

</style>