<template>
  <template v-if="loggedIn">
    <nav id="sidebarMenu" class="app-nav-menu sidebar">
      <NavMenu ref="navmenu" />
    </nav> 
    <header>
      <NavHeader/>
    </header>  
    <div class="container-fluid" style="padding-left: 65px; padding-right: 0px;">
      <main id="app-main-content" :class="{'is-home': $route.path == '/' }">
        <router-view :key="$route.path"/>
      </main>
    </div>
  </template>
  <template v-else>
    <LoginComponent/>
  </template>
</template>
<script>
  import NavHeader from '@/components/NavHeader.vue'
  import NavMenu from '@/components/NavMenu.vue'
  import LoginComponent from '@/views/login/Login.vue';
  import http from '@/http-common'
  import dataservice from '@/services/DataService';
  import store from '@/store/user';
  import dataStore from '@/store/data';
  //import Echo from 'laravel-echo';
  //import Pusher from 'pusher-js';

  export default {
    data() {
        return {
          loggedIn: false,
          refreshed: true,
          storedEmployeeOverviewData: {}
        }
    },
    watch: {
        $route: {
            immediate: true,
            handler(to) {
              console.log('route changed', to);
              document.title = process.env.VUE_APP_TITLE +' | ' + to.name;
              if(this.refreshed && to.name && ["Locaties", "Nieuwe locatie", "Ketens", "Nieuwe keten", "Groepen", "Nieuwe groep"].includes(to.name)){
                this.refreshed = false;
                this.$refs.navmenu.openLocations();
              }
            }
        },
    },
    components: {
      NavHeader,
      NavMenu,
      LoginComponent
    },
    computed: {
      isLoggedIn() {
        let loggedIn = store.getters.isLoggedIn;
        return loggedIn;
      }
    },
    mounted () {
      http.defaults['headers']['Authorization'] = store.getters.getAuthHeader
      this.loggedIn = (store.getters.isLoggedIn) ? true : false;
      dataservice.getData().then(response => {
        dataStore.dispatch('getData', response.data);
        store.dispatch('userUpdate', response.data.user);
      }).catch(error => {
        console.log(error);
      });

      /*window.Pusher = Pusher;
      window.Echo = new Echo({
          broadcaster: 'pusher',
          key: process.env.VUE_APP_PUSHER_KEY,
          cluster: "eu",
          forceTLS: true
      });

      if(this.loggedIn){
        window.activeChannel = `user-update-${store.getters.userId}`;
        window.Echo.channel(window.activeChannel)
          .listen('.listen-user-update', (e) => {
            console.log(e);
        });
      }*/
    }
  }
</script>
<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#app-main-content.is-home {
  background-color: #f6f6f6;
  min-height: calc(100vh - 65px);
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.fixed-top {
  padding: 40px;
  padding-left: 105px;
}

.sticky-bottom {
  padding: 30px 12px 40px 12px;
  background-color: white;
  border-top: 1px solid #f1f1f1;
  translate: -1.5rem;
  width: calc(100% + 2.25rem)
}
</style>
