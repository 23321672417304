<template>
  <Overview
        :moduleName="$tc(navName, 1)"
        :moduleNamePlural="$tc(navName, 2)"
        sort="name"
        :newButtonName="$t('form.add', [$t(navName)])"
        :selectFields=selectFields
        :itemService=itemService
        :itemFormRef=itemFormRef
        ref="overview"
    >
        <template #columns>
            <Column field="name" :header="$t('overview.name')" :sortable="true" style="width: 25%">
                <template #body="slotProps">
                    <div class="uploaded-logo uploaded-logo-sm" :style="{'background-image': `url(${slotProps.data.icon_url})`}"></div>
                    {{slotProps.data.name}}
                </template>
            </Column>
            <Column field="hospitality_group.name" header="Groep" :sortable="true"> </Column>
            <Column field="dealer.name" header="Dealer" :sortable="true"> </Column>
        </template>
    </Overview>
</template>

<script>
import Overview from '@/components/Overview.vue';
import Column from 'primevue/column';
import fsService from '@/services/FranchiseService'
import store from '@/store/user'

export default {
    components: {
        Overview,
        Column
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.$refs.overview.getIndex(from);
        })
    },
    data() {
        return {
            item: {},
            itemService: fsService,
            itemFormRef: null,
            navName: 'navigation.franchises',
            companyTypes: [],
            selectFields: []
        }
    },
    mounted(){
        //Not customers allowed
        if(store.getters.getUserType == 2){
            this.$router.push({name: 'Home'});
        }
    }
}
</script>
