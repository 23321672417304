<template>
    <div class="modal fade" id="formBrokerModal" tabindex="-1" aria-labelledby="brokerModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
            <div class="modal-content">
                <div class="modal-header justify-content-between">
                    <h5 class="modal-title" id="brokerModalLabel">
                        {{$tc('location.broker_change', item.source_connection.broker_uuid ? 2 : 1)}}
                    </h5>
                    <div>
                        <button type="button" class="btn-close pt-3" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                </div>
                <div class="modal-body pt-0">
                    <div class="d-flex fw-bold position-sticky pt-2">
                        <div></div>
                        <div class="col-6">Token</div>
                        <div class="col">Hostname</div>
                        <div class="col-3">Laatste verbinding</div>
                    </div>
                    <label v-for="client_connection of item.client_connections" :key="client_connection.uuid" class="d-flex" :for="'trxx-'+client_connection.uuid">
                        <div><input type='radio' v-model="valueData" name="client_connection_uuid" :value="client_connection.uuid" :id="'trxx-'+client_connection.uuid"></div>
                        <div class="col-6">{{client_connection.uuid}}</div>
                        <div class="col">{{client_connection.hostname}}</div>
                        <div class="col-3">{{formatDate(client_connection.last_connection, 'dateTime')}}</div>
                    </label>
                </div>
                <div class="modal-footer"> 
                    <button id="brokersavebutton" type="button" class="btn btn-unidark" data-bs-dismiss="modal" @click="save">Opslaan</button> 
                </div>
            </div>
        </div>
    </div>
    <button class="d-none" data-bs-toggle="modal" data-bs-target="#formBrokerModal" id="formBrokerModalTrigger"></button>
</template>

<script>
    export default {
        name: 'CustomerSocketBrokerPopup',
        props: {
            item: {
                type: Object,
                required: true
            },
            modelValue: {}
        },
        watch: {
			modelValue: {
				immediate: true,
				handler() {
					this.valueData = this.modelValue;
				}
			}
		},
        data () {
			return {
                valueData: null
			}
		},
        methods: {
            trigger(item) {
                document.getElementById('formBrokerModalTrigger').click();
            },
            save(){
                this.$parent.item.source_connection.broker_uuid = this.valueData;
            }
        }
    }

</script>
<style scoped>
#formBrokerModal {
    z-index: 2222
}
.d-flex > div:first-child{
    width: 24px;
}
.position-sticky{
    top: 0px;
    background-color: white;
}
</style>